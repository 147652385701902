<template>
  <div :class="`c-motivation c-motivation--${popupVisible}`">
    <div class="c-motivation__content">
      <div class="o-container">
        <div class="o-row">
          <div class="col-xs-12 col-lg-10 offset-lg-1 col-xlg-8 offset-xlg-2">
            <div class="c-motivation__wrapper">
              <button class="c-motivation__close" @click="closeMotivation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  />
                </svg>
              </button>
              <h3 class="c-motivation__title">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                  /></svg
                >Toelichting door {{ currentParty }}:
              </h3>
              <p>{{ currentMotivation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-table">
    <div class="c-table__wrapper">
      <table>
        <tr>
          <th></th>
          <th class="c-table__row c-table__row--bg c-table__row--sticky">
            <span class="c-table__label c-table__label--party">Jouw keuze</span>
          </th>
          <th
            class="c-table__row"
            v-for="(party, index) in answers"
            :key="index"
          >
            <span class="c-table__label c-table__label--party">{{
              party.name
            }}</span>
          </th>
        </tr>
        <tr v-for="(question, index) in questions" :key="index">
          <th>
            <span class="c-table__label">{{ question.short }}</span>
          </th>
          <th class="c-table__row c-table__row--sticky">
            <span
              :class="`c-table__unit c-table__unit--${userAnswers[index]}`"
            ></span>
          </th>
          <th
            class="c-table__row"
            v-for="(party, indexB) in answers"
            :key="indexB"
          >
            <button
              :class="`c-table__unit c-table__unit--${party.answers[index].answer}`"
              @click="
                openMotivation(party.answers[index].motivation, party.name)
              "
            ></button>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultsTable",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    userAnswers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupVisible: false,
      currentMotivation: "",
      currentParty: "",
    };
  },
  methods: {
    openMotivation(motivation, party) {
      this.currentMotivation = motivation;
      this.currentParty = party;
      this.popupVisible = true;
    },
    closeMotivation() {
      this.popupVisible = false;
      this.currentMotivation = "";
      this.currentParty = "";
    },
  },
};
</script>
