<template>
  <header class="c-header">
    <div class="o-container">
      <a
        href="https://www.gentsmilieufront.be/"
        title="Website Gents Milieu Front"
        class="c-logo__wrapper"
      >
        <img
          class="c-logo"
          alt="Gents Milieu Front logo"
          src="../assets/img/logo.png"
        />
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "SiteHeader",
};
</script>
