<template>
  <div class="c-share">
    <h3 class="c-share__title">
      Nodig anderen uit om de kieswijzer in te vullen!
    </h3>
    <div class="c-share__links">
      <a
        class="c-share__link"
        data-social="facebook"
        target="_blank"
        aria-label="Deel De Gentse Kieswijzer op Facebook"
        href="https://www.facebook.com/sharer/sharer.php?u=https://gentsekieswijzer.be/"
        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
          /></svg
      ></a>
      <a
        class="c-share__link"
        data-social="x"
        target="_blank"
        aria-label="Deel De Gentse Kieswijzer op X/ Twitter"
        href="http://twitter.com/share?url=https://gentsekieswijzer.be/"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
          />
        </svg>
      </a>
      <a
        @click="copyUrl"
        class="c-share__link"
        data-social="link"
        href="#"
        aria-label="Kopieer de URL van De Gentse Kieswijzer"
        target="_blank"
      >
        <p v-if="copySuccess" class="c-share__succes-message">
          URL gekopieerd!
        </p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <path
            d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareLinks",
  data() {
    return {
      copySuccess: false,
    };
  },
  methods: {
    copyUrl(e) {
      const url = "https://gentsekieswijzer.be/";
      e.preventDefault();
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 2000);
        })
        .catch((err) => {
          console.error("Er is een fout opgelopen bij het kopiëren: ", err);
        });
    },
  },
};
</script>
