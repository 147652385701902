<template>
  <section class="c-hero">
    <div class="o-container">
      <div class="c-hero__body">
        <div class="c-hero__text">
          <h1>De Gentse Kieswijzer</h1>
          <p>
            Een klimaatrobuuste en gezonde stad, een stad waar iedereen een
            goede woning vindt: hoe gaan de Gentse politieke partijen daaraan
            werken? Ontdek het met de kieswijzer van Gents MilieuFront!
          </p>
          <button class="c-hero__scrollto" @click="scrollToQuestions">
            Doe de test<svg
              width="15"
              height="9"
              viewBox="0 0 15 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.74356 8.67048C7.16194 9.10984 7.8414 9.10984 8.25979 8.67048L14.6862 1.92179C15.1046 1.48243 15.1046 0.768893 14.6862 0.329526C14.2678 -0.109842 13.5884 -0.109842 13.17 0.329526L7.5 6.28383L1.83002 0.33304C1.41164 -0.106327 0.732177 -0.106327 0.31379 0.33304C-0.104597 0.772407 -0.104597 1.48594 0.31379 1.92531L6.74021 8.67399L6.74356 8.67048Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div class="c-hero__image">
          <img
            alt="Gents Milieu Front draakje"
            src="../assets/img/draakje.png"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroDefdault",
  methods: {
    scrollToQuestions() {
      const element = document.querySelector(".c-questions");
      if (element) {
        const offset =
          2 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Calculate 2rem in pixels
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
