<template>
  <footer class="c-footer">
    <div class="o-container">
      <div class="c-footer-body">
        <a
          href="https://www.gentsmilieufront.be/"
          title="Website Gents Milieu Front"
          class="c-logo__wrapper"
        >
          <img
            class="c-logo"
            alt="Gents Milieu Front logo"
            src="../assets/img/logo-inverted.png"
          />
        </a>
        <div class="c-footer-logos">
          <a
            href="https://www.facebook.com/gentsmilieufront"
            target="_blank"
            class="c-footer__logo"
            aria-label="Facebook GMF"
            ><svg
              width="10"
              height="20"
              viewBox="0 0 10 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.2626 11.632V19.8984H6.23929V11.632H9.20466L9.82173 7.83111H6.23929V6.48641C6.23929 4.47714 6.93521 3.70763 8.73157 3.70763C9.29037 3.70763 9.73946 3.72318 10 3.75427V0.307026C9.50977 0.155456 8.30991 0 7.61741 0C3.95269 0 2.2626 1.96263 2.2626 6.19493V7.83111H0V11.632H2.2626Z"
                fill="#2F2F31"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UCf-b7NS-lEwOeHKppQ5KhDw"
            target="_blank"
            class="c-footer__logo"
            aria-label="Youtube GMF"
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0868 4.36289C18.868 3.52969 18.2257 2.87578 17.4097 2.6543C15.9305 2.25 9.99997 2.25 9.99997 2.25C9.99997 2.25 4.06942 2.25 2.59025 2.6543C1.77428 2.87578 1.13192 3.52969 0.913167 4.36289C0.517334 5.87109 0.517334 9.01406 0.517334 9.01406C0.517334 9.01406 0.517334 12.157 0.913167 13.6652C1.13192 14.4984 1.77428 15.1242 2.59025 15.3457C4.06942 15.75 9.99997 15.75 9.99997 15.75C9.99997 15.75 15.9305 15.75 17.4097 15.3457C18.2257 15.1242 18.868 14.4949 19.0868 13.6652C19.4826 12.157 19.4826 9.01406 19.4826 9.01406C19.4826 9.01406 19.4826 5.87109 19.0868 4.36289ZM8.06247 11.8688V6.15937L13.0173 9.01406L8.06247 11.8688Z"
                fill="#2F2F31"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/gentsmilieufront/"
            target="_blank"
            class="c-footer__logo"
            aria-label="Instagram GMF"
          >
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00179 4.30889C5.73162 4.30889 3.9005 6.33358 3.9005 8.84373C3.9005 11.3539 5.73162 13.3786 8.00179 13.3786C10.2719 13.3786 12.1031 11.3539 12.1031 8.84373C12.1031 6.33358 10.2719 4.30889 8.00179 4.30889ZM8.00179 11.792C6.53475 11.792 5.33542 10.4698 5.33542 8.84373C5.33542 7.21766 6.53118 5.89549 8.00179 5.89549C9.47239 5.89549 10.6682 7.21766 10.6682 8.84373C10.6682 10.4698 9.46882 11.792 8.00179 11.792ZM13.2274 4.12339C13.2274 4.71146 12.7991 5.18113 12.2708 5.18113C11.739 5.18113 11.3142 4.70751 11.3142 4.12339C11.3142 3.53927 11.7426 3.06566 12.2708 3.06566C12.7991 3.06566 13.2274 3.53927 13.2274 4.12339ZM15.9438 5.19691C15.8831 3.78002 15.5904 2.52495 14.6516 1.49089C13.7165 0.456839 12.5814 0.133204 11.2999 0.0621617C9.97925 -0.0207206 6.02075 -0.0207206 4.70006 0.0621617C3.4222 0.129257 2.28712 0.452892 1.34835 1.48695C0.409593 2.521 0.120468 3.77607 0.0562186 5.19297C-0.0187395 6.65327 -0.0187395 11.0302 0.0562186 12.4905C0.116899 13.9074 0.409593 15.1625 1.34835 16.1966C2.28712 17.2306 3.41863 17.5543 4.70006 17.6253C6.02075 17.7082 9.97925 17.7082 11.2999 17.6253C12.5814 17.5582 13.7165 17.2346 14.6516 16.1966C15.5868 15.1625 15.8795 13.9074 15.9438 12.4905C16.0187 11.0302 16.0187 6.65722 15.9438 5.19691ZM14.2376 14.0574C13.9592 14.831 13.4202 15.4269 12.717 15.7387C11.664 16.2005 9.16542 16.094 8.00179 16.094C6.83815 16.094 4.33597 16.1966 3.28656 15.7387C2.58695 15.4309 2.04796 14.8349 1.76598 14.0574C1.34835 12.8931 1.44473 10.1304 1.44473 8.84373C1.44473 7.55708 1.35192 4.79039 1.76598 3.63004C2.04439 2.85648 2.58338 2.26051 3.28656 1.94872C4.33954 1.48695 6.83815 1.59351 8.00179 1.59351C9.16542 1.59351 11.6676 1.49089 12.717 1.94872C13.4166 2.25657 13.9556 2.85253 14.2376 3.63004C14.6552 4.79434 14.5588 7.55708 14.5588 8.84373C14.5588 10.1304 14.6552 12.8971 14.2376 14.0574Z"
                fill="#2F2F31"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
};
</script>
