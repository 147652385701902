<template>
  <SiteHeader />
  <HeroDefault />
  <QuestionSegment :slides="questions" :parties="parties" />
  <SiteFooter />
</template>

<script>
import QuestionSegment from "./components/QuestionSegment.vue";
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";
import HeroDefault from "./components/HeroDefault.vue";

export default {
  name: "App",
  components: {
    SiteHeader,
    QuestionSegment,
    SiteFooter,
    HeroDefault,
  },
  data() {
    return {
      parties: [
        {
          name: "cd&v",
          color: "orange",
          hex: "#FF6000",
          img_url: "cdv",
          answers: [
            {
              answer: true,
              motivation:
                "Door Vergroening als bevoegdheid van een schepen te expliciteren wil cd&v de inspanningen die vandaag vanuit meerdere bevoegdheden worden geleverd rond ontharden, vergroenen en biodiversiteit beter op elkaar afstemmen.",
            },
            {
              answer: true,
              motivation:
                "Cd&v zet in op vergroening en onderschrijft de doelstelling om tegen 2030 een halve meter bijkomende haag of geveltuin aan te planten per inwoner. De afgelopen jaren werden hierin al stappen gezet waarop we verder kunnen bouwen. Belangrijk voor ons is dat de overheid hierin stimulerend optreedt, niet verplichtend.",
            },
            {
              answer: true,
              motivation:
                "Cd&v wil verder bouwen aan de acht groenklimaatassen. De groenklimaatassen geven de Gentenaars de kans om op een aangename, vlotte en veilige manier toegang te krijgen tot de verschillende groenpolen die onze stad rijk is. De as langs de De Pintelaan wordt hierbij versterkt.",
            },
            {
              answer: true,
              motivation:
                "Via toepassing van de 3-30-300-regel wil cd&v elke Gentenaar laten genieten van groen in hun buurt. Daarbij gaat het niet enkel over de hoeveelheid groen, maar ook over de beleving en de verkoelende werking ervan. We zetten in op dataverzameling, om zo doelgericht de wijken met de hoogste noden versneld te vergroenen.",
            },
            {
              answer: true,
              motivation:
                "Cd&v ziet pleinen als ontmoetingsplekken met ruimte voor groen, water, en zit- en speelelementen. Intensieve vergroening is echter (nog) niet voor elk plein haalbaar, bv. door gebruik voor evenementen en markten of de parkeernood. Kleinere pleinen die minder intensief gebruikt worden bieden het meeste mogelijkheden.",
            },
            {
              answer: false,
              motivation:
                "De fly-over kan volgens cd&v niet los worden gezien van de andere bovenlokale weginfrastructuur rondom en in Gent. Het Complex Project Viaduct Gentbrugge, waarin Stad Gent actief betrokken is, moet een ambitieuze en duurzame oplossing bieden. Idealiter gaat de B401 ondergronds, maar meer studiewerk is nodig.",
            },
            {
              answer: false,
              motivation:
                "Cd&v wil de gratis eerste bewonerskaart behouden. Het betalend maken van elke bewonerskaart treft in de eerste plaats de minst vermogenden, zelfs bij sociale correcties. Het is niet voor iedereen mogelijk om in de stad te leven zonder wagen, en cd&v wil mensen daar ook niet toe dwingen.",
            },
            {
              answer: true,
              motivation:
                "Om de leefbaarheid voor de bewoners en de verkeersveiligheid voor de zachte weggebruikers te verhogen wil cd&v werk maken van een autoluwere en groenere stadsring. We leiden het verkeer dat de beweging rond Gent wil maken naar de R4. De verkeersafwikkeling en bereikbaarheid moeten wel gegarandeerd blijven.",
            },
            {
              answer: false,
              motivation:
                "Er is, zeker in een aantal wijken, een zeer grote nood aan parkeerplaatsen. Cd&v is van mening dat Gentenaars hun wagen moeten kunnen stallen in de buurt van hun woning. Wagens die in een garagebox staan, maken plaats vrij op het openbaar domein.",
            },
            {
              answer: true,
              motivation:
                "Cd&v wil een beter en performanter openbaar vervoer in Gent. De budgetten hiervoor moeten in eerste instantie worden voorzien in Vlaanderen. De Stad doet al veel om hiaten in te vullen. We blijven hier verder op inzetten zolang het nodig is.",
            },
            {
              answer: true,
              motivation:
                "Cd&v blijft pleiten voor regelmatig bediende bus- of tramhaltes op wandelafstand van de woning van elke Gentenaar. Een afstand van 300 meter is daarbij geen maximum, maar wel het streefdoel. We dringen daarop aan bij de Vlaamse overheid en De Lijn.",
            },
            {
              answer: true,
              motivation:
                "Bijkomende capaciteit rond het station is nodig. Cd&v wil ook een alternatief voor de fietsenstallingen op het Koningin Maria Hendrikaplein. Het inzetten van onderbezette delen van de ondergrondse parking kan ertoe bijdragen dat er voldoende plaats is voor fietsen, én dat het plein terug een aangename plek wordt.",
            },
            {
              answer: true,
              motivation:
                "Cd&v ondersteunt het ambitieuze plan van Thuispunt Gent om het aanbod sociale woningen uit te breiden en wil daar binnen de stadsbegroting ook de noodzakelijke middelen voor uittrekken.",
            },
            {
              answer: false,
              motivation:
                "Cd&v wil inwoners stimuleren om te kiezen voor een duurzame verwarmingsbron en wil de huishoudelijke verwarming via houtstook ontraden door informatie- en sensibiliseringsacties. We waken erover dat kwetsbare groepen worden begeleid en ondersteund. Een uitdoofbeleid impliceert een onwenselijke en onhaalbare handhaving.",
            },
            {
              answer: true,
              motivation:
                "Bomen zorgen voor verkoeling, zuiveren de lucht en verfraaien het uitzicht. Cd&v wil dat bij grote werven bomen worden beschermd. We nemen het boombeschermingsplan en de precieze invulling ervan op in een bredere oefening die de regeldruk voor ontwikkelaars aanpakt.",
            },
          ],
        },
        {
          name: "Groen",
          color: "green",
          hex: "#02AD5B",
          img_url: "groen",
          answers: [
            {
              answer: true,
              motivation:
                "Beleid rond vergroening zit best bij één schepen, zowel voor publiek als privaat domein. Het gaat daarbij niet alleen over tuinen maar ook over bedrijventerreinen. Verstandig ruimtegebruik maakt op dergelijke terreinen veel vergroening mogelijk.",
            },
            {
              answer: true,
              motivation:
                "We zetten in op beide doelstellingen: hagen in het buitengebied en in privétuinen, en geveltuinen in de binnenstad. We werken hiervoor samen met landbouwers, en adviseren en steunen bewoners en organisaties als de Geveltuinbrigade. Het project Planten met Buren verdient navolging.",
            },
            {
              answer: true,
              motivation:
                "We reanimeren het concept van de groenklimaatassen en versnellen de realisatie naar minstens 2 klimaatassen per legislatuur. We leggen historische waterlopen zoals de Blaisantvest en de Tichelrei opnieuw open, en brengen leven in en om het water met groene vooroevers aan de Franse Vaart, de Visserij en het Achterdok.",
            },
            {
              answer: true,
              motivation:
                "Wij omarmen het concept van de 3-30-300-regel van Cecil Konijnendijk gretig, en vullen graag aan. Voor ons hoort het publiek toegankelijke groen op 300 meter wandelafstand (de 300) minstens één hectare groot te zijn. En: elke Gentenaar heeft recht op een groot natuurgebied op 3km van de deur (natuur op fietsafstand).",
            },
            {
              answer: true,
              motivation:
                "De openbare ruimte is er voor iedereen. In de stad is er nood aan meer groene ruimte en ruimte voor mensgerichte functies, zoals ontspannen, spelen, afkoelen... De omvorming biedt ook kansen voor verenigingen en handelaars om meer gebruik te maken van de publieke ruimte en de stad levendiger of juist rustiger te maken.",
            },
            {
              answer: true,
              motivation:
                "We trekken en sleuren als Groen Gent aan alle partners (Vlaamse overheid is eigenaar) om de fly-over op een andere manier in te vullen of af te breken. Dus helemaal akkoord. Zeker een actie die op korte termijn moet kunnen!",
            },
            {
              answer: true,
              motivation:
                "Groen vindt het niet fair om 12 m² van de openbare ruimte gratis te claimen. We voorzien een mobiliteitsbudget voor elke Gentenaar onder een bepaalde inkomensgrens, vrij te besteden aan openbaar vervoer, een fiets of een parkeerkaart. Zo komt ruimte vrij voor bomen, zitbankjes, bushaltes etc.",
            },
            {
              answer: true,
              motivation:
                "Groen is al lang vragende partij om van de stadsring (R40) een publieke ruimte te maken die veel aangenamer, veiliger en rustiger is en enkel nog lokaal verkeer verwerkt. De duizenden bewoners langs de R40 verdienen een betere luchtkwaliteit. Bovenlokaal verkeer kan beter langs de R4, die we dan ook optimaliseren.",
            },
            {
              answer: true,
              motivation:
                "Garageboxterreinen liggen vaak in woonblokken met te weinig groen en kennen veel onbenutte ruimte. Groen wil transitietrajecten opzetten om de opbergbehoefte collectief op te vangen (fietsenstallingen, gestapelde stockeerruimte…). Ook het delen van materiaal kan voor veel Gentenaars een oplossing bieden.",
            },
            {
              answer: true,
              motivation:
                "Het is onbegrijpelijk dat er budgetneutraliteit is voor De Lijn, maar individuele elektrische voertuigen premies krijgen. Er moet fors geïnvesteerd worden in meer, kwalitatiever en betrouwbaarder openbaar vervoer, met oplossingen voor de zwaksten. Het Flexnet is op dit moment manifest onvoldoende!",
            },
            {
              answer: true,
              motivation:
                "We willen nabij, comfortabel en kwalitatief openbaar vervoer. In de stad betekenen de doelstellingen van nabij en snel openbaar vervoer een evenwichtsoefening. Het principe van nabijheid ondersteunen we voluit, en ook hier geldt: alles begint met een Vlaamse regering die ambitieus investeert in openbaar vervoer.",
            },
            {
              answer: true,
              motivation:
                "Een deel van de ondergrondse autoparking kan zeker fietsenparking worden. De NMBS is een belangrijke actor in duurzame mobiliteit en heeft als zodanig een verantwoordelijkheid om duurzaam voor- en natransport te faciliteren. Dit is belangrijk voor reizigers en ook voor de kwaliteit van het Hendrikaplein.",
            },
            {
              answer: true,
              motivation:
                "We steunen Thuispunt Gent om voldoende, kwalitatieve en klimaatneutrale sociale woningen te bouwen en te onderhouden. De huidige financiële Vlaamse ondersteuning volstaat niet om de ambities waar te maken. Daarom ondersteunen we met investeringssubsidies. Wonen in Gent is niet alleen weggelegd voor wie het goed heeft.",
            },
            {
              answer: true,
              motivation:
                "Met een sociaal onderbouwd kachelplan verminderen we de uitstoot van (zeer) ongezonde stoffen uit houtverbranding. We informeren over gezondheidskwesties, voorzien in een omschakelpremie en stellen - voor huishoudens die alternatieve verwarmingsbronnen hebben - een stookverbod in bij slechte luchtkwaliteit.",
            },
            {
              answer: true,
              motivation:
                "Dit is al een belangrijk aandachtspunt en verdient nog beter uitgebouwd te worden. Het vormt daarom een essentieel onderdeel van de nieuwe eisen van Stad Gent op vlak van boomsparend ontwerpen. Ook handhaving mag steviger: beschadigde bomen door onzorgvuldige werken, dat pikken we in Gent niet meer.",
            },
          ],
        },
        {
          name: "N-VA",
          color: "yellow",
          hex: "#F9B919",
          img_url: "nva",
          answers: [
            {
              answer: true,
              motivation:
                "Deze doelstellingen steunen we, wanneer het gaat om positief aanmoedigend beleid en zonder een extra schepenmandaat te creëren. Verplichtend of sanctionerend optreden is uit den boze. De afgelopen bestuursperiode deden we als oppositiepartij bijvoorbeeld al voorstellen om Gentenaars te stimuleren om bomen te planten.",
            },
            {
              answer: true,
              motivation:
                "We steunen deze Vlaamse ambitie. Vergroening zorgt voor meer leefbaarheid. De stad moet hier rechtstreeks werk van maken m.b.t. het eigen patrimonium en tegelijk burgers en ondernemingen stimuleren om hetzelfde te doen. Twee voorwaarden bij geveltuinen: de vlotte doorgang niet belemmeren en degelijk onderhouden worden.",
            },
            {
              answer: true,
              motivation:
                "Versneld investeren in kwalitatieve en goed onderhouden openbare ruimte is voor ons belangrijk. Het realiseren van de blauw-groene verbindingsassen tussen de centrumstad en de grote groenpolen maakt hier deel van uit. Dit komt alle Gentenaars ten goede en betekent een belangrijke meerwaarde op lange termijn.",
            },
            {
              answer: true,
              motivation:
                "Alle Gentenaars hebben baat bij zicht op en vlotte toegang tot groen. We omarmen de 3-30-300-vuistregel als leidraad bij de heraanlegprojecten en nieuwe ontwikkelingen. Onze interpretatie is in de eerste plaats ‘naar de geest’ en minder ‘naar de letter’, want naast groen zijn er natuurlijk ook andere noden.",
            },
            {
              answer: false,
              motivation:
                "Het autovrij maken van pleinen met parkeermogelijkheid kan voor ons niet, tenzij er een volwaardig alternatief met duidelijk draagvlak bij de lokale bewoners/ondernemers is. Vergroenen kan zeker wel en graag, zolang andere belangrijke functies van de pleinen in kwestie (feesten, evenementen, …) niet belemmerd worden.",
            },
            {
              answer: false,
              motivation:
                "De B401 is een belangrijke toegangsweg tot het stadscentrum. De komende jaren staan er ook al ingrijpende werken gepland op belangrijke verkeersassen en -knooppunten (o.a. Dampoort, Gentspoort, E17-viaduct). In die zin is op dit moment een extra mega-werf creëren niet aangewezen.",
            },
            {
              answer: false,
              motivation:
                "Met N-VA Gent willen we de eerste bewonerskaart gratis houden. Deze betalend maken dreigt de stadsvlucht van (jonge) gezinnen nog te versterken. Voor veel werkende – en lokale belastingen betalende – Gentenaars blijft een auto noodzaak.",
            },
            {
              answer: false,
              motivation:
                "Sinds het mobiliteitsplan (2017) is de kleine ring meer dan ooit de toegangsweg tot de centrumwijken. De R40 autoluwer maken door deze te beperken tot één rijstrook zou de vlotte bereikbaarheid van onze stad negatief beïnvloeden. Vergroenen kan als dit de verkeersveiligheid en bereikbaarheid niet in het gedrang brengt.",
            },
            {
              answer: false,
              motivation:
                "Garageboxterreinen zijn vaak niet de meest aantrekkelijke locaties, maar komen wel tegemoet aan de reële parkeernood van Gentenaars. Als stad via overleg de herontwikkeling van terreinen stimuleren, met daarbij het voorzien van alternatieve parkeermogelijkheden, kan natuurlijk wel.",
            },
            {
              answer: false,
              motivation:
                "Vlaamse middelen bepleiten om objectieve pijnpunten (duidelijke vraag, geen afdoende aanbod of alternatief) in het Gentse openbaar vervoer aan te pakken, is uiteraard geen probleem. Maar de toestand van de Gentse stadskas is niet rooskleurig en maakt extra stedelijke investeringen weinig haalbaar.",
            },
            {
              answer: false,
              motivation:
                "Met N-VA Gent steunen we het principe van vraaggestuurde ‘basisbereikbaarheid’. Niemand is gebaat bij dure (bijna) lege bussen op trajecten waar de vraag beperkt is. Het vervoer-op-maat moet hier een degelijk alternatief bieden en pijnpunten daarin dienen opgelost te worden.",
            },
            {
              answer: false,
              motivation:
                "Problemen op vlak van fietsparkeren los je niet op door problemen te creëren op vlak van autoparkeren. Dat er bijkomende fietsparkeerplaatsen moeten komen: prima, maar niet ten koste van autoparkeren.",
            },
            {
              answer: false,
              motivation:
                "Voor ons telt werk maken van betaalbaar wonen voor álle Gentenaars. De exclusieve focus op sociaal wonen is niet de juiste weg. Er moeten ook middelen naar groepen die net niet in aanmerking komen voor een sociale woning. Voor een betere leefbaarheid van sociale wooncomplexen is financiële ondersteuning wel wenselijk.",
            },
            {
              answer: false,
              motivation:
                "Voor de gezelligheid genieten van de open haard moet blijven kunnen in Gent. Mensen die van houtstook afhankelijk zijn voor de structurele verwarming van hun woning moedigen we via vzw REGent aan om te kiezen voor milieuvriendelijkere alternatieven.",
            },
            {
              answer: false,
              motivation:
                "Het principe – de bescherming van bomen – zijn we genegen. Maar uitgereikte omgevingsvergunningen leggen ter zake nu al strenge voorwaarden op die moeten worden nageleefd. Nog eens extra planlasten creëren drijft de kosten alleen maar op, wat dan bijvoorbeeld voor minder betaalbaar woonaanbod zorgt.",
            },
          ],
        },
        {
          name: "PVDA",
          color: "darkred",
          hex: "#EF4136",
          img_url: "pvda",
          answers: [
            {
              answer: true,
              motivation:
                "De stad neemt positieve, motiverende maatregelen en ondersteunt haar inwoners via advies, coaching, groepsaankopen, enzovoort. Er wordt bijzondere aandacht geschonken aan verticale-, terras- en balkontuintjes voor Gentenaars zonder eigen tuin.",
            },
            {
              answer: true,
              motivation:
                "We versterken de werking van de Geveltuinbrigade om deze doelstelling te realiseren. We schalen op die manier het aantal (gratis) geveltuintjes en verticale beplanting op, in samenwerking met de stad.",
            },
            {
              answer: true,
              motivation:
                "Aantrekkelijke fietspaden en fietsroutes, afgescheiden van het autoverkeer zorgen voor veiliger fietsverkeer en motiveren de Gentenaars bij de keuze voor een duurzaam alternatief. Groenklimaatassen zijn hierbij een wenselijke oplossing.",
            },
            {
              answer: true,
              motivation:
                "Bomen en planten zijn essentieel voor een gezonde leefomgeving. Ze zouden een basisrecht moeten zijn voor elke stadsbewoner. Willen we voor iedereen een leefbare stedelijke omgeving, dan hebben we een globale visie nodig. De 3-30-300-regel is het uitgangspunt.",
            },
            {
              answer: true,
              motivation:
                "Om dit mogelijk te maken, investeren we drastisch in gratis, fijnmazig en frequenter openbaar vervoer, maken we autodeelsystemen goedkoper en zorgen we voor gratis buurtparkings voor bewoners.",
            },
            {
              answer: true,
              motivation: "Geen toelichting.",
            },
            {
              answer: false,
              motivation:
                "Bewonerskaarten moeten gratis blijven. Mensen extra belasten lost niets op, integendeel, het verkleint het draagvlak en straft werknemers die zonder eigen auto niet op hun werk geraken. De PVDA pleit daarentegen voor positieve alternatieven zoals betaalbare autodeelsystemen en beter openbaar vervoer.",
            },
            {
              answer: true,
              motivation:
                "Wij willen van de R40 een leefbare en veilige binnenring maken, met bomenrijen en afgescheiden fietspaden, en een vlotte doorstroming van het verkeer. Een tramlijn op- en langs het parcours van de R40 en forse investeringen in gratis en frequent openbaar vervoer zijn daarbij essentiële voorwaarden.",
            },
            {
              answer: false,
              motivation:
                "Veel kleiner behuisde huishoudens gebruiken een garagebox om fietsen te stallen of spullen op te bergen. Hogere belastingen zijn contraproductief en weinig sociaal. Beter is om wijk per wijk met stakeholders een ruimtevisie te ontwikkelen, en daarbij ook op garageboxen in te zoomen.",
            },
            {
              answer: true,
              motivation:
                "Wij pleiten voor gratis openbaar vervoer, zoals in Luxemburg en Duinkerke. Enkel door drastische investeringen in ons openbaar vervoer, kan een sterk én gratis openbaar vervoer worden uitgebouwd. Waar De Lijn en de Vlaamse regering tekortschieten, moet de stad extra investeren met eigen middelen.",
            },
            {
              answer: true,
              motivation:
                "Het huidige openbaarvervoersplan heeft voor chaos gezorgd: lijnen werden hertekend, aansluitingen werden niet meer gehaald, haltes werden omgevormd naar een flexbediening. Dat moet anders, we pleiten voor een verfijning van het reguliere net, met bijkomende haltes en dus ook haltes op wandelafstand.",
            },
            {
              answer: true,
              motivation:
                "Voldoende kwaliteitsvolle, overdekte fietsparkeerplaatsen bij het vernieuwde Sint-Pietersstation zijn een must. Aangezien de werken aan het station nog enkele jaren zullen duren, bekijken we in afwachting samen met de NMBS of er vrije ruimte van de autoparking kan worden ingezet voor fietsen.",
            },
            {
              answer: true,
              motivation:
                "Om de wachtlijst voor sociale woningen en de wooncrisis in het algemeen aan te pakken moet er drastisch geïnvesteerd worden in sociale woningen. Ook de stad moet hier een financiële verantwoordelijkheid nemen om de ambities waar te maken.",
            },
            {
              answer: false,
              motivation:
                "Een verbod op houtstook is een te drastische stap, dat zal het draagvlak helemaal onderuit halen. Wij zetten in op preventie en bewustmaking, ook moet er een betere regulering komen over o.a. de plaatsing van een correct rookgaskanaal en -monding, en wordt er opgetreden bij klachten over hinder en overlast.",
            },
            {
              answer: true,
              motivation: "Geen toelichting.",
            },
          ],
        },
        {
          name: "Voor Gent",
          color: "pink",
          hex: "#FF0080",
          img_url: "voorgent",
          answers: [
            {
              answer: true,
              motivation:
                "Voor Gent onderschrijft de doelstelling om te gaan voor meer vergroening en minder verharding. Meer openbaar groen is voor ons niet enkel een klimaatdoelstelling, maar zorgt ook voor kwalitatieve publieke ruimte voor alle Gentenaars. ",
            },
            {
              answer: true,
              motivation:
                "Voor Gent wil dat de stad minstens 3000m² gevelgroen per jaar bijplant, op muren van stadspatrimonium en op gevels palend aan het publiek domein. Gentenaars en bedrijven worden ook aangespoord om geveltuinen aan te leggen.",
            },
            {
              answer: true,
              motivation:
                "Op termijn moeten alle groenklimaatassen gerealiseerd worden. Dit zijn ingrijpende maatregelen en complexe ontwikkelingen. Eerlijkheid gebiedt ons te stellen dat we niet zomaar kunnen stellen dat er drie assen volledig gerealiseerd zullen worden, maar we willen  simultaan werken aan de diverse assen.",
            },
            {
              answer: true,
              motivation:
                "Voor Gent onderschrijft de 3-30-300-regel. We prioriteren de boomarmste wijken, zoals de Binnenstad en Rabot. We willen zoveel mogelijk toekomstbomen. In totaal gaan we voor min.150.000 extra bomen, waarvan min. 1500 straatbomen. Bij nieuwe stadsontwikkelingsprojecten en grote private projecten leggen we de regel op.",
            },
            {
              answer: true,
              motivation:
                "Voor Gent wil o.a. het Edward Anseeleplein, Kettingplein, dorpsplein van Sint-Denijs-Westrem en het Casinoplein ontharden. Daarnaast voorzien we o.a. bijkomend groen met waterinfiltratie aan Bij Sint-Jacobs, het Rerum Novarumplein en het Ledebergplein. Het François Laurentplein geven we terug aan de Nederschelde.",
            },
            {
              answer: true,
              motivation:
                "Voor Gent is voorstander van een structurele, toekomstbestendige invulling van de fly-over. Een afbraak op lange termijn creëert meer ruimte voor leefbaarheid in het centrum. Op korte termijn willen we dat het laatste deel van de B401 (voorbij de Keizerpoort) alleen nog toegang geeft tot de (uitgebreide) parking Zuid.",
            },
            {
              answer: false,
              motivation:
                "We voorzien alternatieven voor individueel autobezit en -gebruik (deelmobiliteit mét sociaal tarief). Maar we houden rekening met mensen voor wie de auto noodzakelijk blijft (minder mobiele mensen, ploegarbeiders). Gratis eerste bewonerskaart blijft, want wie geen keuze heeft, zadelen we niet op met extra kosten.",
            },
            {
              answer: false,
              motivation:
                "We zijn voorstander om zoveel mogelijk verkeer naar het hogere wegennet toe te leiden. Gentspoort moet zorgen voor meer leefbaarheid op de R40 en ruimte voor openbaar vervoer. Voor een goede doorstroom van het verkeer rondom en in Gent is op dit moment downsizen van de R40 van twee naar één rijvak niet realistisch.",
            },
            {
              answer: true,
              motivation:
                "Garageboxen moeten een andere invulling krijgen (extra woningen, groen, economie). Dit realiseren we door een sterk stedenbouwkundig beleid. Een garagebelasting zal doorgerekend worden aan de huurder zonder dat dit tot een andere invulling leidt. We voorzien alternatieven (autodelen, buurtparkings) voor de parkeerdruk.",
            },
            {
              answer: true,
              motivation:
                "We vragen meer middelen aan Vlaanderen voor het openbaar vervoer o.a. een grootstedelijke ringlijn met bussen die via de R4 de deelgemeenten verbindt, om blinde vlekken in wijken aan te pakken en voldoende aanbod naar alle bedrijventerreinen. We investeren zelf door gratis openbaar vervoer uit te breiden tot 18 jaar.",
            },
            {
              answer: true,
              motivation:
                "We willen elke halte van het openbaar vervoer integraal toegankelijk tegen 2030. Om doorstroming te verbeteren zetten we in op vrije beddingen, verkeerslichtenbeïnvloeding en uitgestulpte haltes. We dringen aan bij Vlaanderen om het nieuwe vervoersplan van De Lijn bij te sturen en opnieuw meer haltes te voorzien. ",
            },
            {
              answer: false,
              motivation:
                "We gaan op zoek naar ongebruikte ruimtes met mogelijkheden om deze fietsen in de onmiddellijke omgeving inpandig te stallen.  We dringen erop aan bij de NMBS dat alle fietsstallingen aan stations bewaakt zijn. De autoparking vervangen door fietsenstallingen zal enkel de parkeerdruk verplaatsen naar de buurt.",
            },
            {
              answer: true,
              motivation:
                "We willen dat de stad blijft bijdragen aan de financiering van Thuispunt Gent en blijven aandringen op een betere financiering vanuit Vlaanderen. Daarnaast willen we via alternatieve financiering en samenwerking met de private sector een extra impuls geven aan de bouw van sociale en betaalbare woningen. ",
            },
            {
              answer: false,
              motivation:
                "Voor Gent staat voor een ambitieus en sociaal klimaatbeleid met collectieve maatregelen. We willen mensen stimuleren naar andere energiebronnen, niet bestraffen. Een dergelijk verbod overstijgt de lokale bevoegdheid en is niet handhaafbaar. Bovendien willen we iedereen mee in het klimaatverhaal en draagvlak creëren. ",
            },
            {
              answer: true,
              motivation:
                "Voor Gent staat achter het principe van boombescherming. Bij projectontwikkelingen hebben we zoveel mogelijk oog om bestaande grote bomen te sparen. We bekijken of een aparte vergunning hiervoor het gepaste instrument is. ",
            },
          ],
        },
      ],
      questions: [
        {
          short: "Schepen vergroening",
          question:
            "Er komt een schepen van vergroening die niet alleen het publiek groen beheert, maar ook motiverend beleid opzet rond de Gentse private tuinen.",
          info: "Met een totale oppervlakte van ongeveer 1500 hectare bieden de Gentse private tuinen enorme kansen om te ontharden, vergroenen en de biodiversiteit alle kansen te geven.",
        },
        {
          short: "Haag/geveltuin",
          question:
            "Gent volgt de Vlaamse doelstelling en plant een halve meter bijkomende haag of geveltuin per inwoner.",
          info: "Hagen en geveltuinen zijn een zegen voor de stedelijke biodiversiteit. Gent ondertekende het Lokaal Energie- en Klimaatpact waarin deze ambitie staat. Een halve meter per Gentenaar = 130 kilometer hagen en geveltuinen!",
        },
        {
          short: "Groenklimaatassen",
          question:
            "Gent realiseert minstens 3 van de 8 groenklimaatassen (waaronder de as langs de De Pintelaan, die in de voorbije bestuursperiode wegbespaard werd).",
          info: "Groenklimaatassen vormen, in de woorden van de Stad, “een natuur- en waterrijk netwerk dat de binnenstad verbindt voor fietsers, wandelaars en fauna en flora met de open ruimte en de vijf groenpolen aan de rand van Gent”. Ruimte voor Gent, de Gentse structuurvisie voor 2030-2050, gaat uit van de versnelde realisatie van de groenklimaatassen.",
        },
        {
          short: "3-30-300-regel",
          question:
            "Gent omarmt de 3-30-300-regel om de wijken ambitieus te vergroenen. De prioriteit ligt bij dichtbevolkte wijken met weinig groen.",
          info: "Zicht op 3 bomen vanuit elk huis en kantoor, 30 procent boomkruinoppervlak per wijk en een groene plek op 300 meter wandelafstand: ook het Vlaamse Klimaatgezondheidsplan omarmde intussen de 3-30-300-regel.",
        },
        {
          short: "Autovrij plein",
          question:
            "Gent maakt minstens één plein per jaar autovrij en groener.",
          info: "Heel veel pleinen worden vandaag nog gedomineerd door autoparkeren: Bij Sint-Jacobs, Anseeleplein, Joremaaie, Ledebergplein, Dampoortplein enz. Het Maaseikplein is een geslaagd voorbeeld van hoe een parkeerplein een publiekslieveling kan worden.",
        },
        {
          short: "Fly-over",
          question:
            "De fly-over (B401) is genoeg bestudeerd. Hoog tijd om werk te maken van een toekomstbestendige invulling met minder auto’s en meer woon- en verblijfskwaliteit.",
          info: "Studies hierover lopen al sinds 2017. Eind ‘24 komt de Vlaamse administratie met een mobiliteitsstudie. Daarna is het aan de lokale en bovenlokale politiek om knopen door te hakken zodat de geleidelijke verkeersafbouw eindelijk kan beginnen.",
        },
        {
          short: "Parkeerkaart kosten",
          question:
            "Bewoners met een eigen auto betalen voor hun parkeerkaart volgens het gezinsinkomen, de aankoopwaarde en de uitstoot van de auto.",
          info: "In Gent is de eerste bewonerskaart vandaag gratis. In heel wat steden in binnen- en buitenland is dat anders. Een variabel tarief is sociaal rechtvaardiger dan een vast tarief.",
        },
        {
          short: "R40 stadsboulevard",
          question:
            "Gent start samen met de Vlaamse wegbeheerder een traject om van de kleine ring (R40) een autoluwere en groenere stadsboulevard te maken.",
          info: "Het Gentse bestuursakkoord (‘19-’24) beloofde: “De stadsring krijgt op termijn de allure van een stadsboulevard, met meer evenwicht tussen de verblijfsfunctie en de verkeersfunctie.” De Vlaamse overheid dicteert dat elke weg binnen de R4 een lokale weg is, “ingericht voor duurzame verplaatsingen en aangename leefomgevingen”.",
        },
        {
          short: "Garageboxterreinen",
          question:
            "Gent streeft naar een andere invulling van garageboxterreinen, en verhoogt op zijn minst de belastingen voor terreineigenaars.",
          info: "Garageboxen komen tegemoet aan één nood: opslagruimte. Andere belangrijke noden zijn: groen, waterinfiltratie, collectieve warmtepompen, speel- en ontmoetingsplekken. Hogere belastingen motiveren om een terrein anders in te vullen of te verkopen aan de Stad.",
        },
        {
          short: "De Lijn budget",
          question:
            "Gent verzet zich krachtig tegen de Vlaamse budgetneutraliteit bij De Lijn en vergroot de eigen middelen die het in openbaar vervoer investeert.",
          info: "De Vlaamse overheid verplicht De Lijn om budgetneutraal te werken: als ergens een halte of lijn bijkomt, moet er dus elders een verdwijnen, of moet men besparen op personeel of materiaal. De gratis Buzzy Pazz tussen 6 en 14 jaar is een voorbeeld van eigen investeringen van de Stad.",
        },
        {
          short: "OV halteafstand",
          question:
            "Gent streeft ernaar dat iedereen binnen de bebouwde kom een regelmatig bediende bus- of tramhalte heeft op maximum 300 meter van de deur.",
          info: "De wandelafstand is een belangrijke factor bij de afweging om voor bus of tram te kiezen. Dit is uiteraard extra belangrijk voor wie niet goed te been is. Stad Gent kan een maximum afstand bepleiten binnen de vervoerregioraad, en hierover adviseren in het vergunningenbeleid.",
        },
        {
          short: "Fietsparkeerplaatsen",
          question:
            "Om het aantal fietsparkeerplaatsen rond het Sint-Pietersstation op korte termijn te verhogen sluit Gent een deal met de NMBS over het gebruik van de ondergrondse autoparking voor fietsenstalling.",
          info: "Er zijn op dit moment 14.000 fietsparkeerplaatsen, en dat is duidelijk veel te weinig. Het uiteindelijke streefdoel is 17.500, maar door de stijgende populariteit van de fiets is ook dat aantal niet voldoende.",
        },
        {
          short: "Thuispunt investering",
          question:
            "Gent investeert 24 miljoen euro in Thuispunt Gent, het bedrag dat de organisatie van de Stad vraagt om zijn ambities rond sociale woningen te kunnen realiseren.",
          info: "De sociale huisvestingsmaatschappij investeert de komende tien jaar anderhalf miljard in renovatie en nieuwbouw van duizenden sociale woningen en vraagt daarbij ook inspanningen van het stadsbestuur.",
        },
        {
          short: "Uitdoofbeleid houtstook",
          question:
            "Er komt een uitdoofbeleid van de houtstook, met uitgebreide steun voor gezinnen die niet louter voor de gezelligheid stoken.",
          info: "De rook van houtvuur bevat (veel) ongezonde stoffen, zowel in als rond huis. Dit fijn stof kan zelfs kanker veroorzaken. Naar schatting is houtstook slechts bij een zeer klein aantal gezinnen de primaire verwarmingsbron.",
        },
        {
          short: "Boombeschermingsplan",
          question:
            "Een grote werf wordt enkel vergund met een boombeschermingsplan, opgesteld en uitgevoerd onder toezicht van een voldoende gecertificeerd bomenspecialist.",
          info: "Bodemverdichting door vrachtwagens, verdroging, ophoging van de grond enzoverder: bomen zijn zeer gevoelig voor schade. Om dit te voorkomen is het nodig om echte boomexperten in te schakelen, en dat al in een vroege fase.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main";
</style>
