<template>
  <section class="c-questions">
    <div class="o-container">
      <div class="o-row">
        <div class="col-xs-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div class="c-pagination">
            <p>
              Vraag
              <span class="c-pagination__amt"> {{ currentSlide + 1 }}</span> van
              15
            </p>
          </div>
          <div class="c-questionnaire swiper">
            <swiper
              ref="swiper"
              :modules="modules"
              :slides-per-view="1"
              :space-between="30"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :allow-touch-move="false"
              @slideChange="onSlideChange"
              @reachEnd="lastSlide"
              :initialSlide="initialSlide"
            >
              <swiper-slide v-for="(slide, index) in slides" :key="index">
                <div class="c-questionnaire__segment slide-content">
                  <div class="c-question">
                    <div class="c-question__wrapper">
                      <h3>{{ slide.question }}</h3>
                      <div class="c-question__info">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM10.125 15.75H11.25V12.75H10.125C9.50156 12.75 9 12.2484 9 11.625C9 11.0016 9.50156 10.5 10.125 10.5H12.375C12.9984 10.5 13.5 11.0016 13.5 11.625V15.75H13.875C14.4984 15.75 15 16.2516 15 16.875C15 17.4984 14.4984 18 13.875 18H10.125C9.50156 18 9 17.4984 9 16.875C9 16.2516 9.50156 15.75 10.125 15.75ZM12 6C12.3978 6 12.7794 6.15804 13.0607 6.43934C13.342 6.72064 13.5 7.10218 13.5 7.5C13.5 7.89782 13.342 8.27936 13.0607 8.56066C12.7794 8.84196 12.3978 9 12 9C11.6022 9 11.2206 8.84196 10.9393 8.56066C10.658 8.27936 10.5 7.89782 10.5 7.5C10.5 7.10218 10.658 6.72064 10.9393 6.43934C11.2206 6.15804 11.6022 6 12 6Z"
                            fill="#00A75D"
                          />
                        </svg>
                        <div class="c-question__info-wrapper">
                          <p>{{ slide.info }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="c-question__buttons">
                      <button
                        class="c-question__button-true"
                        @click="selectAnswer(index, true)"
                        :class="{ active: answers[index] == true }"
                      >
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30.3562 3.26249C26.775 2.24061 23.0344 4.31249 22.0125 7.89374L21.4781 9.76874C21.1313 10.9875 20.5031 12.1125 19.65 13.05L14.8406 18.3375C14.0063 19.2562 14.0719 20.6812 14.9906 21.5156C15.9094 22.35 17.3344 22.2844 18.1688 21.3656L22.9781 16.0781C24.3 14.625 25.2656 12.8906 25.8 11.0062L26.3344 9.13124C26.6719 7.94061 27.9188 7.24686 29.1188 7.58436C30.3188 7.92186 31.0031 9.16874 30.6656 10.3687L30.1312 12.2437C29.5969 14.1094 28.7531 15.8719 27.6375 17.4469C27.15 18.1312 27.0938 19.0312 27.4781 19.7812C27.8625 20.5312 28.6312 21 29.475 21H42C42.825 21 43.5 21.675 43.5 22.5C43.5 23.1375 43.0969 23.6906 42.525 23.9062C41.8312 24.1687 41.3063 24.75 41.1281 25.4719C40.95 26.1937 41.1375 26.9531 41.625 27.5062C41.8594 27.7687 42 28.1156 42 28.5C42 29.2312 41.475 29.8406 40.7812 29.9719C40.0125 30.1219 39.3656 30.6562 39.0938 31.3969C38.8219 32.1375 38.9438 32.9625 39.4313 33.5812C39.6281 33.8344 39.75 34.1531 39.75 34.5094C39.75 35.1375 39.3562 35.6906 38.7937 35.9062C37.7156 36.3281 37.1344 37.4906 37.4437 38.6062C37.4813 38.7281 37.5 38.8687 37.5 39.0094C37.5 39.8344 36.825 40.5094 36 40.5094H26.8594C25.6781 40.5094 24.5156 40.1625 23.5312 39.5062L17.7469 35.6531C16.7156 34.9594 15.3188 35.2406 14.625 36.2812C13.9312 37.3219 14.2125 38.7094 15.2531 39.4031L21.0375 43.2562C22.7625 44.4094 24.7875 45.0187 26.8594 45.0187H36C39.2531 45.0187 41.8969 42.4312 42 39.2062C43.3688 38.1094 44.25 36.4219 44.25 34.5187C44.25 34.0969 44.2031 33.6937 44.1281 33.3C45.5719 32.2031 46.5 30.4687 46.5 28.5187C46.5 27.9094 46.4062 27.3187 46.2375 26.7656C47.325 25.6594 48 24.1594 48 22.5C48 19.1906 45.3187 16.5 42 16.5H33.3469C33.7875 15.525 34.1625 14.5125 34.4531 13.4812L34.9875 11.6062C36.0094 8.02499 33.9375 4.28436 30.3562 3.26249ZM3 18C1.34062 18 0 19.3406 0 21V42C0 43.6594 1.34062 45 3 45H9C10.6594 45 12 43.6594 12 42V21C12 19.3406 10.6594 18 9 18H3Z"
                            fill="#00A75D"
                          />
                        </svg>
                        Eens
                      </button>
                      <button
                        class="c-question__button-false"
                        @click="selectAnswer(index, false)"
                        :class="{ active: answers[index] == false }"
                      >
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30.3562 44.7375C26.775 45.7594 23.0344 43.6875 22.0125 40.1063L21.4781 38.2313C21.1313 37.0125 20.5031 35.8875 19.65 34.95L14.8406 29.6625C14.0063 28.7438 14.0719 27.3188 14.9906 26.4844C15.9094 25.65 17.3344 25.7156 18.1688 26.6344L22.9781 31.9219C24.3 33.375 25.2656 35.1094 25.8 36.9938L26.3344 38.8688C26.6719 40.0594 27.9188 40.7531 29.1188 40.4156C30.3188 40.0781 31.0031 38.8313 30.6656 37.6313L30.1312 35.7563C29.5969 33.8906 28.7531 32.1281 27.6375 30.5531C27.15 29.8688 27.0938 28.9688 27.4781 28.2188C27.8625 27.4688 28.6312 27 29.475 27H42C42.825 27 43.5 26.325 43.5 25.5C43.5 24.8625 43.0969 24.3094 42.525 24.0938C41.8313 23.8313 41.3063 23.25 41.1281 22.5281C40.95 21.8063 41.1375 21.0469 41.625 20.4938C41.8594 20.2313 42 19.8844 42 19.5C42 18.7688 41.475 18.1594 40.7812 18.0281C40.0125 17.8781 39.3656 17.3438 39.0938 16.6031C38.8219 15.8625 38.9438 15.0375 39.4313 14.4188C39.6281 14.1656 39.75 13.8469 39.75 13.4906C39.75 12.8625 39.3562 12.3094 38.7937 12.0938C37.7156 11.6719 37.1344 10.5094 37.4437 9.39376C37.4812 9.27189 37.5 9.13126 37.5 8.99064C37.5 8.16564 36.825 7.49064 36 7.49064H26.8594C25.6781 7.49064 24.5156 7.83751 23.5312 8.49376L17.7469 12.3469C16.7156 13.0406 15.3188 12.7594 14.625 11.7188C13.9312 10.6781 14.2125 9.29064 15.2531 8.59689L21.0375 4.74376C22.7625 3.59064 24.7875 2.98126 26.8594 2.98126H36C39.2531 2.98126 41.8969 5.56876 42 8.79376C43.3688 9.89064 44.25 11.5781 44.25 13.4813C44.25 13.9031 44.2031 14.3063 44.1281 14.7C45.5719 15.7969 46.5 17.5313 46.5 19.4813C46.5 20.0906 46.4063 20.6813 46.2375 21.2344C47.325 22.3406 48 23.8406 48 25.5C48 28.8094 45.3188 31.5 42 31.5H33.3469C33.7875 32.475 34.1625 33.4875 34.4531 34.5188L34.9875 36.3938C36.0094 39.975 33.9375 43.7156 30.3562 44.7375ZM3 36C1.34062 36 0 34.6594 0 33V12C0 10.3406 1.34062 9.00001 3 9.00001H9C10.6594 9.00001 12 10.3406 12 12V33C12 34.6594 10.6594 36 9 36H3Z"
                            fill="#FF4343"
                          />
                        </svg>
                        Oneens
                      </button>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-controls">
              <button
                class="swiper-button-prev"
                :class="{ hidden: currentSlide === 0 }"
              >
                Vorige vraag
              </button>
              <button
                class="swiper-button-next"
                :class="[
                  { disabled: answers[currentSlide] === undefined },
                  { none: currentSlide === slides.length - 1 },
                ]"
              >
                Volgende vraag
              </button>
              <button
                @click="toggleModal"
                class="swiper-button-result"
                :class="[
                  { disabled: currentSlide !== answers.length - 1 },
                  { none: currentSlide !== slides.length - 1 },
                ]"
              >
                Toon resultaten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="c-modal-wrapper" aria-hidden="true" @click="closeModal">
    <div class="c-modal" ref="modal">
      <button class="c-modal__close" @click="toggleModal">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM186.3 136.5L256 218.8l69.7-82.3c8.6-10.1 23.7-11.4 33.8-2.8s11.4 23.7 2.8 33.8L287.4 256l74.9 88.5c8.6 10.1 7.3 25.3-2.8 33.8s-25.3 7.3-33.8-2.8L256 293.2l-69.7 82.3c-8.6 10.1-23.7 11.4-33.8 2.8s-11.4-23.7-2.8-33.8L224.6 256l-74.9-88.5c-8.6-10.1-7.3-25.3 2.8-33.8s25.3-7.3 33.8 2.8z"
          />
        </svg>
      </button>
      <h2 class="c-modal__title">Resultaten</h2>
      <div class="c-modal__cta">
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_90_12)">
            <path
              d="M7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15ZM7.5 3.75C7.88965 3.75 8.20312 4.06348 8.20312 4.45312V7.73438C8.20312 8.12402 7.88965 8.4375 7.5 8.4375C7.11035 8.4375 6.79688 8.12402 6.79688 7.73438V4.45312C6.79688 4.06348 7.11035 3.75 7.5 3.75ZM6.5625 10.3125C6.5625 10.0639 6.66127 9.8254 6.83709 9.64959C7.0129 9.47377 7.25136 9.375 7.5 9.375C7.74864 9.375 7.9871 9.47377 8.16291 9.64959C8.33873 9.8254 8.4375 10.0639 8.4375 10.3125C8.4375 10.5611 8.33873 10.7996 8.16291 10.9754C7.9871 11.1512 7.74864 11.25 7.5 11.25C7.25136 11.25 7.0129 11.1512 6.83709 10.9754C6.66127 10.7996 6.5625 10.5611 6.5625 10.3125Z"
              fill="#02AD5B"
            />
          </g>
          <defs>
            <clipPath id="clip0_90_12">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          >Scrol naar beneden en klik op een bolletje om het standpunt per
          partij te bekijken.</span
        >
      </div>
      <h4 class="c-modal__smalltitle">Jouw match</h4>
      <div class="c-modal__parties">
        <div
          v-for="(party, index) in parties"
          :key="index"
          :class="`c-party c-party--${party.color}`"
        >
          <div class="c-party__logo">
            <img
              :alt="`Logo ${party.name}`"
              :src="require(`../assets/img/${party.img_url}.png`)"
            />
          </div>
          <h4 class="c-party__title">{{ party.name }}</h4>
          <div class="c-party__percentage">
            <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
              <circle
                class="donut-hole"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="#fff"
              ></circle>
              <circle
                class="donut-ring"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke="#ffffff"
                stroke-width="5"
              ></circle>

              <circle
                class="donut-segment"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                :stroke="party.hex"
                stroke-width="5"
                :stroke-dasharray="`${calculateMatchingPercentage(party)} ${
                  100 - calculateMatchingPercentage(party)
                }`"
                stroke-dashoffset="25"
              ></circle>
            </svg>
            <span>{{ calculateMatchingAmount(party) }}/15</span>
          </div>
        </div>
      </div>
      <ResultsTable
        :userAnswers="answers"
        :answers="parties"
        :questions="slides"
      />
      <ShareLinks />
      <CTA />
    </div>
  </section>
</template>

<script>
// Import scripts
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, A11y } from "swiper/modules";
import { setCookie, getCookie } from "../utils/cookies";

// Import styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import components
import CTA from "./CTA.vue";
import ResultsTable from "./ResultsTable.vue";
import ShareLinks from "./ShareLinks.vue";

export default {
  name: "QuestionSegment",
  components: {
    Swiper,
    SwiperSlide,
    CTA,
    ResultsTable,
    ShareLinks,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
    parties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  setup() {
    const currentSlide = ref(0);

    const onSlideChange = (swiper) => {
      currentSlide.value = swiper.activeIndex;
    };

    const lastSlide = () => {};

    return {
      currentSlide,
      onSlideChange,
      lastSlide,
      modules: [Pagination, Navigation, A11y],
    };
  },
  created() {
    const cookieValue = getCookie("answers");
    if (cookieValue) {
      this.answers = JSON.parse(cookieValue);
    }
  },
  methods: {
    selectAnswer(index, answer) {
      if (this.answers && this.answers[index] !== undefined) {
        this.answers[index] = answer;
      } else if (this.answers) {
        this.answers.push(answer);
      }
      setCookie("answers", JSON.stringify(this.answers), 2);
    },
    lockScroll() {
      document.body.style.overflow = "hidden";
    },
    unlockScroll() {
      document.body.style.overflow = "";
    },
    closeModal(event) {
      if (!this.$refs.modal.contains(event.target)) {
        let modal = document.querySelector(".c-modal-wrapper");
        modal.setAttribute("aria-hidden", true);
        document.body.style.overflow = "auto";
      }
    },
    toggleModal() {
      this.pushData();

      let modal = document.querySelector(".c-modal-wrapper");
      let isHidden = modal.getAttribute("aria-hidden") === "true";
      modal.setAttribute("aria-hidden", !isHidden);
      document.body.style.overflow = isHidden ? "hidden" : "auto";
    },
    calculateMatchingPercentage(party) {
      const totalQuestions = this.slides.length;
      const matchingAnswers = this.answers.filter((answer, index) => {
        return answer === party.answers[index].answer;
      }).length;
      return (matchingAnswers / totalQuestions) * 100;
    },
    calculateMatchingAmount(party) {
      const matchingAnswers = this.answers.filter((answer, index) => {
        return answer === party.answers[index].answer;
      }).length;
      return matchingAnswers;
    },
    pushData() {
      let userResult = [];

      for (let key in this.parties) {
        userResult.push({
          partij: this.parties[key].name,
          score:
            this.calculateMatchingAmount(this.parties[key]) +
            "/" +
            this.slides.length,
        });
      }

      let favoriteParty = userResult.reduce((max, party) => {
        const currentScore = parseInt(party.score.split("/")[0], 10);
        const maxScore = parseInt(max.score.split("/")[0], 10);
        return currentScore > maxScore ? party : max;
      }, userResult[0]);

      this.$gtm.trackEvent({
        event: "user_results",
        category: "Kieswijzer",
        action: "click",
        label: "Resulaten van de gebruiker",
        value: userResult,
        favoriete_partij: favoriteParty.partij,
      });
    },
  },
  computed: {
    initialSlide() {
      const cookieValue = getCookie("answers");
      if (cookieValue) {
        return JSON.parse(cookieValue).length;
      } else {
        return 0;
      }
    },
  },
};
</script>
