<template>
  <div class="c-cta">
    <div class="o-container">
      <div class="o-row">
        <div class="col-xs-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <a
            class="c-cta__body"
            href="https://www.gentsmilieufront.be/events/verkiezingsdebat-i-s-m-saamo-gent-moet-klimaat-sociaal-zijn/"
            title="Tickets voor het verkiezingsdebat van het Gents MilieuFront"
            target="_blank"
          >
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="c-cta__icon"
            >
              <g clip-path="url(#clip0_74_1432)">
                <path
                  d="M0.888889 4.8125C0.398056 4.8125 0 5.20434 0 5.6875V8.3125C0 8.79566 0.398056 9.1875 0.888889 9.1875C1.205 9.1875 1.46944 9.01578 1.62694 8.77133L4.59889 9.65453C4.50583 9.92141 4.44444 10.2025 4.44444 10.5C4.44444 11.9476 5.64056 13.125 7.11111 13.125C8.34694 13.125 9.37944 12.2891 9.68056 11.1645L13.3333 12.25V1.75L1.62694 5.22867C1.46944 4.98422 1.205 4.8125 0.888889 4.8125ZM5.86917 10.0319L8.41 10.7868C8.27639 11.3728 7.74583 11.8125 7.11111 11.8125C6.37583 11.8125 5.77778 11.2238 5.77778 10.5C5.77778 10.3346 5.81222 10.1776 5.86917 10.0319ZM15.5556 0.875H14.6667C14.4211 0.875 14.2222 1.07078 14.2222 1.3125V12.6875C14.2222 12.9292 14.4211 13.125 14.6667 13.125H15.5556C15.8011 13.125 16 12.9292 16 12.6875V1.3125C16 1.07078 15.8011 0.875 15.5556 0.875Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_74_1432">
                  <rect width="16" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div class="c-cta__text">
              <h3>Kom naar ons verkiezingsdebat</h3>
              <p>
                Nog niet genoeg info? Kom naar ons debat op 18/9! Klik hier voor
                info en tickets.
              </p>
            </div>
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="c-cta__arrow"
            >
              <path
                d="M10.5972 9.90773C11.1343 9.40567 11.1343 8.59032 10.5972 8.08825L2.34886 0.376547C1.81185 -0.125517 0.939757 -0.125517 0.402753 0.376547C-0.134252 0.878611 -0.134252 1.69396 0.402753 2.19603L7.68024 9L0.407049 15.804C-0.129956 16.306 -0.129956 17.1214 0.407049 17.6235C0.944054 18.1255 1.81615 18.1255 2.35315 17.6235L10.6015 9.91175L10.5972 9.90773Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
};
</script>
